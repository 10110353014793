@use "@angular/material" as mat;
@include mat.core();

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "swiper/swiper-bundle.css";

// FONTS
@import "./assets/fonts/RedHatDisplay/RedHatDisplay.css";
@import "./assets/fonts/RedHatText/RedHatText.css";
@import "./assets/fonts/NotoSans/NotoSans.css";

@import "src/app/styles/config.scss";
@import "src/app/styles/text.scss";
@import "src/app/styles/form.scss";
@import "src/app/styles/layout.scss";
@import "src/app/styles/colors.scss";

// Material
@import "src/app/styles/material/mat-tab.scss";
@import "src/app/styles/material/mat-ripple.scss";
@import "src/app/styles/material/mat-checkbox.scss";
@import "src/app/styles/material/mat-select.scss";
@import "src/app/styles/material/mat-table.scss";
@import "@angular/material/select/_select-theme.scss";

//

html {
  font-family: var(--app-font-family);
}

/* add the code bellow */
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: var(--scrollbar-width);
  background-color: transparent;
}

::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}

::-webkit-scrollbar-thumb:hover {
  /*background: var(--dirty-purple);*/
  background: var(--scrollbar-thumb-hover-color);
}

@media screen and (min-width: 1279px) {
  html {
    font-size: 24px;
  }
}
