h1 {
  color: var(--grey-100, #5e5357);
  font-feature-settings:
    "liga" off,
    "clig" off;
  /* h1 */
  font-family: "RedHatDisplay";
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.625rem; /* 131.25% */
  letter-spacing: -0.025rem;
}

h2 {
  color: var(--grey-100, #5e5357);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: "RedHatDisplay";
  font-size: 1.4375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem; /* 130.435% */
  letter-spacing: -0.01875rem;
}

h3 {
  color: var(--grey-100, #5e5357);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: "RedHatDisplay";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 133.333% */
  letter-spacing: -0.01875rem;
}

h4 {
  color: var(--grey-100, #5e5357);
  font-feature-settings:
    "liga" off,
    "clig" off;
  /* h4 */
  font-family: "RedHatDisplay";
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem; /* 125% */
  letter-spacing: -0.0125rem;
}

h5 {
  color: var(--grey-100, #5e5357);
  font-feature-settings:
    "liga" off,
    "clig" off;
  font-family: "RedHatDisplay";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem; /* 128.571% */
}

.bold {
  color: var(--grey-100, #5e5357);
  /* h5/medium */
  font-family: "RedHatDisplay";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 128.571% */
}

.link {
  color: var(--grey-100, #5e5357);
  font-feature-settings:
    "liga" off,
    "clig" off;
  /* label text/small */
  font-family: "RedHatDisplay";
  font-size: var(--6x);
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
