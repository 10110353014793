:root {
  --mat-tab-header-divider-height: var(--1x);
  --mdc-secondary-navigation-tab-container-height: var(--big);

  --mdc-tab-indicator-active-indicator-height: var(--1x);
  --mdc-tab-indicator-active-indicator-shape: var(--1x);
  --mat-tab-animation-duration: 250ms;

  --mat-tab-header-divider-color: var(--grey-50);
  --mdc-tab-indicator-active-indicator-color: var(--tangerine);
  --mat-tab-header-active-focus-indicator-color: var(--tangerine);
  --mat-tab-header-active-hover-indicator-color: var(--tangerine);
  --mat-tab-header-inactive-ripple-color: var(--grey-20);
  --mat-tab-header-active-ripple-color: var(--grey-20);
}

.mdc-tab {
  .dc-tab__text-label {
    font-family: "RedHatDisplay";
  }
  &.mdc-tab--active {
    font-weight: 500;
  }
}

.mdc-tab-indicator {
  .mdc-tab-indicator__content {
    &--underline {
      margin-bottom: -0.125rem;
    }
  }
}

.mat-mdc-tab-header-pagination {
  // &-disabled {
  //   display: none !important;
  // }

  position: sticky !important;
  height: 100%;
  background: white;
  opacity: 1;

  &-before {
    left: 0;
  }

  &-after {
    right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .mat-mdc-tab-header-pagination {
    display: none !important;
  }

  .mat-mdc-tab-header {
    margin-bottom: var(--10x);
    overflow: scroll !important;
  }
  .mat-mdc-tab-header::-webkit-scrollbar {
    display: none !important;
  }

  .mat-mdc-tab-link-container {
    overflow: initial !important;
  }
}
