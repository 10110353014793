.mat-mdc-select:not(.navigator-menu-select) {
  font-family: "RedHatText";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dirty-purple);
  /*display: flex;
  flex-flow: column wrap;
  border: solid 1px rgba(94, 83, 87, 0.25);
  border-radius: 3px;
  background-color: var(--white);
  outline: none;
  padding: 8px 10px;
  min-height: var(--input-min-height);
  overflow: hidden;*/
}

.mat-mdc-option {
  font-family: "RedHatText";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 48px;
  height: 48px;
  padding: 0 16px;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  outline: 0;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  box-sizing: border-box;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}

.mat-mdc-select-panel {
  background: #fff;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.02),
    0 4px 5px 0 rgba(0, 0, 0, 0),
    0 1px 10px 0 rgba(0, 0, 0, 0.1);
}

.mat-mdc-menu-panel {
  min-height: 44px !important;
}

.mat-mdc-select-panel
  .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
  background: rgba(0, 0, 0, 0.06);
}

.mat-checkbox.has-additional-field {
  .mat-checkbox-layout .mat-checkbox-label {
    line-height: normal;
  }
}
