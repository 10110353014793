:root {
  --app-bg: var(--white);
  --app-bg-secondary: var(--white);
  --white: #ffffff;
  --watermelon: #f04b54;
  --salmon: #f2736d;
  --rosey-afterglow: #fbc9cc;
  --berry: #dc6e6a;
  --tangerine: #f7921e;
  --peach: #fbaf57;
  --light-peach: #fcdebb;
  --very-light-peach: #feefdd;
  --rusty-orange: #e06110;
  --grey-20: #e7e5e6;
  --grey-100: #5e5357;
  --grey-70: #8e8789;
  --grey-60: #a6a0a3;
  --grey-40: #d8d6d7;
  --grey-30: #dedbdc;
  --grey-50: #d5d2d2;
  --grey-10: #efeeee;
  --grey-alt: #f3f2f2;
  --grey-alt2: rgba(94, 83, 87, 0.7);
  --azure: #21ade8;
  --light-blue: #71cff5;
  --viridian: #22974b;
  --dark-sea-green: #18a349;
  --dark-peal-olive-green: #7faa69;
  --ice: #def2e6;
  --off-white: #f0f8ec;
  --light-maroon: #944b56;
  --golden: #e7af00;
  --pale-purple: #c490d1;
  --blue: #4f57b1;
  --valerie: #fde4e6;
  --light-mauve: #a7a1a3;
  --retailers-a1000: #e2232d;
  --retailers-coop: #007bd9;
  --retailers-delice: #741434;
  --retailers-grossi: #e80d02;
  --retailers-maxima: #004f9e;
  --retailers-prisma: #00a650;
  --retailers-r-kiosk: #555759;
  --retailers-rimi: #ed1c24;
  --retailers-selver: #f01319;
  --retailers-stockmann: #181c20;
  --very-light-alice-blue: #e8f8ff;
  --peal-olive-green: #9ace81;
  --light-alice-blue: #d4f1fc;
  --frugal: #a3dab6;
  --lime: #d4ebcf;
  --medium-alice-blue: #bdecff;
  --medium-light-peach: #ffd3a3;

  --peach-gradient: linear-gradient(169deg, #fbd7cf -46.75%, #fde4c7 105.69%);
  --dark-peach-gradient: linear-gradient(
    169deg,
    #fac0b4 -46.75%,
    #fcd3a5 105.69%
  );
  --green-gradient: linear-gradient(151deg, #c5e8d2 4.38%, #e1f1dd 116.79%);
  --tangerine-gradient: linear-gradient(
    169deg,
    #f04b54 -46.75%,
    #f7921e 105.69%
  );
  --darker-tangerine-gradient: linear-gradient(
    169deg,
    #f7921e -46.75%,
    #f04b54 105.69%
  );
  --darker-green-gradient: linear-gradient(
    151deg,
    #a3dab6 4.38%,
    #d4ebcf 116.79%
  );
}
