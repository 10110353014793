:root {
  --mat-table-row-item-label-text-font: var(--app-font-family);
}

.mat-mdc-table {
  &.grid-table {
    .mat-mdc-row {
      display: inline-grid !important;

      .mat-mdc-cell {
        border: none !important;
        padding: 0 !important;
      }
    }
  }
}
