:root {
  --big: 2.875rem;
  --medium: 2.5rem;
  --small: 2.125rem;

  --1x: 0.125rem;
  --2x: 0.25rem;
  --2-5x: 0.3125rem;
  --3x: 0.375rem;
  --4x: 0.5rem;
  --5x: 0.625rem;
  --6x: 0.75rem;
  --7x: 0.875rem;
  --8x: 1rem;
  --8-5x: 1.0625rem;
  --9x: 1.125rem;
  --10x: 1.25rem;
  --11x: 1.375rem;
  --12x: 1.5rem;
  --13x: 1.625rem;
  --14x: 1.75rem;
  --15x: 1.875rem;
  --16x: 2rem;
  --17x: 2.125rem;
  --18x: 2.25rem;
  --19x: 2.375rem;
  --20x: 2.5rem;
  --21x: 2.625rem;
  --22x: 2.75rem;
  --23x: 2.875rem;
  --24x: 3rem;
  --25x: 3.125rem;
  --26x: 3.25rem;
  --27x: 3.375rem;
  --28x: 3.5rem;
  --29x: 3.625rem;
  --30x: 3.75rem;
  --31x: 3.875rem;
  --32x: 4rem;
  --33x: 4.125rem;
  --34x: 4.25rem;
  --35x: 4.375rem;
  --36x: 4.5rem;
  --37x: 4.625rem;
  --38x: 4.75rem;
  --39x: 4.875rem;
  --40x: 5rem;
  --41x: 5.125rem;
  --42x: 5.25rem;
  --43x: 5.375rem;
  --44x: 5.5rem;
  --45x: 5.625rem;
  --46x: 5.75rem;
  --47x: 5.875rem;
  --48x: 6rem;
  --49x: 6.125rem;
  --50x: 6.25rem;
  --51x: 6.375rem;
  --52x: 6.5rem;
  --53x: 6.625rem;
  --54x: 6.75rem;
  --55x: 6.875rem;
  --56x: 7rem;
  --57x: 7.125rem;
  --58x: 7.25rem;
  --59x: 7.375rem;
  --60x: 7.5rem;

  --app-font-family: "RedHatText", "NotoSans", "Open Sans", "Segoe UI",
    "Helvetica Neue", "sans-serif";

  --input-padding: 0.4375rem var(--5x, 0.625rem);
  --card-padding: var(--6x);
  --overlay-view-max-height: 100dvh;

  --error-border-color: var(--salmon);
  // SCROLLBAR
  --scrollbar-width: var(--3x);
  --scrollbar-track-color: transparent;
  --scrollbar-thumb-color: rgba(94, 83, 87, 0.28);
  --scrollbar-thumb-hover-color: #c5c5c5;
}

.card-padding {
  padding: var(--card-padding);
}
