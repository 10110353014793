svg-icon {
  svg {
    height: 100%;
    width: 100%;
  }

  &.flag-icon {
    display: inline-flex;
    border-radius: 50%;
    border: 1px solid var(--grey-10);
  }
}

hr {
  border-color: var(--grey-30);
}

#app-container {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  height: calc(100dvh - 3.5rem);
  overflow: auto;
  padding-left: var(--8x);
  padding-right: var(--8x);
  background-color: inherit;
}

#view {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  // // height: calc(100dvh - 6.625rem);
  // height: calc(100dvh - 3.5rem);
  // overflow: auto;
  // padding-left: var(--8x);
  // padding-right: var(--8x);
  background-color: inherit;
}

.card {
  display: flex;
  padding: var(--6x, 0.75rem);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.1875rem;
  background: var(--white);
  /* elevation/1 */
  // box-shadow: 0px 0px 10px 0px rgba(94, 83, 87, 0.15);
  overflow: auto;
  border: 1px solid var(--grey-20);
}
