:root {
  --mat-checkbox-label-text-color: var(--grey-100);
  --mdc-checkbox-state-layer-size: 2.125rem;

  // SELECTED
  --mdc-checkbox-selected-icon-color: var(--tangerine);
  --mdc-checkbox-selected-hover-icon-color: var(--tangerine);
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.2;
  --mdc-checkbox-selected-hover-state-layer-color: var(--grey-50);
  --mdc-checkbox-selected-focus-icon-color: var(--rusty-orange);
  --mdc-checkbox-selected-checkmark-color: var(--white);

  // UNSELECTED
  --mdc-checkbox-unselected-icon-color: var(--grey-50);
  --mdc-checkbox-unselected-hover-icon-color: var(--grey-50);
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.2;
  --mdc-checkbox-unselected-hover-state-layer-color: var(--grey-50);
  --mdc-checkbox-unselected-focus-icon-color: var(--grey-50);
  // var(--rusty-orange)
}
